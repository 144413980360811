export enum OfferTypes {
  midweek = 'midweek',
  seasonal = 'seasonal',
  lateAvailability = 'lateAvailability',
  custom = 'custom',
  bbDiscount = 'bbDiscount',
}

export enum OfferDiscountTypes {
  percentOff = 'percentOff',
  absoluteOff = 'absoluteOff',
  setValue = 'setValue',
  other = 'other',
}
